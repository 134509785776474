// eslint-disable-next-line no-redeclare,no-unused-vars
function saveSettings(send, callback) {
  if (test && user.admin) saveAdminSettings(send, callback);
  else
    ajax({
      post: true,
      object: 'setting',
      send: send,
      callback: callback,
      successInfo: true,
    });
}
